import React, {useEffect, useState} from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Button } from 'theme-ui'
import { Link } from 'gatsby'
import './hero.css'
// import { window } from 'browser-monads'

import book1 from '../../../../../content/assets/book1.png'
import book2 from '../../../../../content/assets/book2.png'
import hero1 from '../../../../../content/assets/hero1.png'
import fleche_vision from '../../../../../content/assets/fleche_vision3.png'



const styles = {
    videoBox : {
      // width: '50%',
      // display: 'flex',
      // justifyContent: 'center',
    },
    videoEmbed : {
        width: '100%',
        height: '600px'
      },
      heroClass_book_w: isMobile => (isMobile ? {
        display: 'none'
      } : {
        position: 'absolute',
        width: '200px',
        left: '650px',
        top: '320px',
      }),
      heroClass_book_w2 : {
        display: 'none'
      },
      heroWrapper : {
        position: 'relative'
      },
      fleche_vision : {
        position: 'absolute',
        Zindex: '9',
        bottom:' -20px',
        right: '70px',
        width: '170px',
      },
      buttons: {
        margin: 'auto',
        marginBottom: "50px",
        width: "71%",
        display: "flex",
        justifyContent: "space-around"
      }

  }



const Hero = props => {

  return (
  <>
    <Box className='heroWrapper'>
        <img src={hero1} className='heroClass' alt='img' />
        <img src={book1} className='heroClass_book_w' alt='img' />
        <img src={book2} className='heroClass_book_w2' alt='img' />
        <Box variant='' sx={styles.buttons}>
        <Button as={Link} to='/category/les-biens/'>
            Les biens
          </Button>
          <Button as={Link} to='https://chloecarmonaimmobilier.systeme.io/guideoffert'>
            Mon guide
          </Button>
        </Box>
        <Box styles={styles.videoBox}>
            <iframe className='videoEmbed' src="https://www.youtube.com/embed/2DZy5mrcvQc"></iframe>
        </Box>
    </Box>


    <img src={fleche_vision}  className='fleche_vision' alt='img' />

  </>
    
  )
}

export default Hero