import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `yellowImmo`,
    fontFamily: 'Nothing You Could Do',
    fontSize: '2rem',
    '@media screen and (max-width: 800px)': {
    fontSize: '1.5rem'
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    textAlign:'justify'
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
      Ensemble, collaborons comme si nous formions une équipe.
    </Heading>
    <Heading variant='h3' sx={styles.running}>
      Je vous propose un service de qualité sur mesure, semblable à un délicieux gâteau dont la recette a été mise au point, grâce à de multiples ingrédients que j’ai gouté dans différents grands réseaux immobiliers depuis 2012.
    </Heading>
    <Heading variant='h3'>Chloé Carmona</Heading>
  </Box>
)
